import { SupportedFiletype, Fileset } from './model';

/* supported filetypes to test, most prominent one first */
const SupportedTestImages = [
    {
        type: SupportedFiletype.Jxl,
        data: '/woAEPxkAgASiAIAtAA94RIAABUqo4wbvJzr+fJDh8W0jesMbbVtYQljs70wSEg4axjazeFmiBgqSQA=',
    },
    {
        type: SupportedFiletype.Avif,
        data: 'AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAOptZXRhAAAAAAAAACFoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAAAAAAA5waXRtAAAAAAABAAAAImlsb2MAAAAAREAAAQABAAAAAAEOAAEAAAAAAAAAFwAAACNpaW5mAAAAAAABAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAAamlwcnAAAABLaXBjbwAAABNjb2xybmNseAACAAIABoAAAAAMYXYxQ4FAbAAAAAAUaXNwZQAAAAAAAAABAAAAAQAAABBwaXhpAAAAAAMMDAwAAAAXaXBtYQAAAAAAAAABAAEEgYIDhAAAAB9tZGF0EgAKBVgABjOEMgwYAA4444QAALASmpg=',
    },
    { type: SupportedFiletype.Webp, data: 'UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAgA0JaQAA3AA/vuUAAA=' },
];

export class ImageFile {
    private static loadTestImage(type: string, data: string): Promise<boolean> {
        return new Promise((resolve, _) => {
            const img = new Image();
            img.addEventListener('load', () => resolve(img.width === 1 && img.height === 1));
            img.addEventListener('error', () => resolve(false));
            img.src = `data:${type};base64,${data}`;
        });
    }

    /**
     * test for support of image/jxl image/avif and image/webp
     */
    private static async detectBrowserSupportedImageFiletypes(): Promise<SupportedFiletype[]> {
        const test = await Promise.all(
            SupportedTestImages.map(async (e) => [await this.loadTestImage(e.type, e.data), e.type])
        );
        return test.filter(([t, _]) => t).map(([_, e]) => e) as SupportedFiletype[];
    }

    private static supportedImageFiletypes?: SupportedFiletype[] = null;

    /**
     * select an approprate entry from the fileset
     *
     * @param fileset
     * @returns
     */
    public static async getImage(fileset: Fileset) {
        if (!ImageFile.supportedImageFiletypes)
            ImageFile.supportedImageFiletypes = await ImageFile.detectBrowserSupportedImageFiletypes();
        for (const file_type of ImageFile.supportedImageFiletypes) {
            if (file_type in fileset) return fileset[file_type];
        }
        return fileset['_'];
    }

    /**
     * Check if provided configuration is valid
     *
     * @param fileset
     * @returns
     */
    public static checkImageConfig(fileset: Fileset) {
        if (!(SupportedFiletype.Default in fileset)) return false;

        for (const [_, v] of Object.entries(fileset)) {
            if (!('path' in v)) return false;
        }

        return true;
    }
}
