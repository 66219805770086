import { BufferGeometry, Mesh, ShaderMaterial, Texture } from 'three';
import type { Viewer } from '@photo-sphere-viewer/core';
import {
    AbstractAdapter,
    EquirectangularAdapter,
    EquirectangularAdapterConfig,
    TextureData,
} from '@photo-sphere-viewer/core';
import { PanoData, PanoDataProvider } from '@photo-sphere-viewer/core';

import { Fileset } from './model';
import { ImageFile } from './utils';

type EquirectangularMesh = Mesh<BufferGeometry, ShaderMaterial>;
type EquirectangularTexture = TextureData<Texture, Fileset>;

export class EquirectangularFiletypesAdapter extends AbstractAdapter<Fileset, Texture> {
    static override readonly id = 'equirectangular-filetypes';

    private readonly internal: EquirectangularAdapter;

    constructor(viewer: Viewer, config: EquirectangularAdapterConfig) {
        super(viewer);
        this.internal = new EquirectangularAdapter(viewer, config);
    }

    override destroy() {
        this.internal.destroy();
        super.destroy();
    }

    override async loadTexture(
        fileset: Fileset,
        newPanoData: PanoData | PanoDataProvider,
        useXmpPanoData = this.viewer.config.useXmpData
    ): Promise<EquirectangularTexture> {
        if (!ImageFile.checkImageConfig(fileset))
            throw new Error('Error in EquirectangularTexture Fileset configuration');

        let image = await ImageFile.getImage(fileset);
        return {
            ...(await this.internal.loadTexture(image.path, newPanoData, useXmpPanoData)),
            panorama: fileset,
        };
    }

    override supportsTransition(_ :Fileset) {
        return true;
    }

    override supportsPreload(_ :Fileset) {
        return true;
    }

    override createMesh(scale?: number) {
        return this.internal.createMesh(scale);
    }
    override setTexture(mesh: EquirectangularMesh, textureData: EquirectangularTexture) {
        ImageFile.getImage(textureData.panorama).then((image) =>
            this.internal.setTexture(mesh, {
                ...textureData,
                panorama: image.path,
            })
        );
    }
    override setTextureOpacity(mesh: EquirectangularMesh, opacity: number) {
        this.internal.setTextureOpacity(mesh, opacity);
    }
    override disposeTexture(textureData: EquirectangularTexture) {
        ImageFile.getImage(textureData.panorama).then((image) =>
            this.internal.disposeTexture({
                ...textureData,
                panorama: image.path,
            })
        );
    }
    override setOverlay(mesh: EquirectangularMesh, textureData: EquirectangularTexture, opacity: number): void {
        ImageFile.getImage(textureData.panorama).then((image) =>
            this.internal.setOverlay(mesh, { ...textureData, panorama: image.path }, opacity)
        );
    }
}
