export enum SupportedFiletype {
    Default = '_',
    Webp = 'image/webp',
    Avif = 'image/avif',
    Jxl = 'image/jxl',
}

export type FilePath = {
    path: string;
};

export type Fileset = {
    [K in SupportedFiletype]: FilePath;
};
